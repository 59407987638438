<template>
  <div class="CedantAccueilRegistration" >
    <div class="row">
      <div class="col-12 col-lg-6 offset-lg-3">
        <div class="gavc-card gavc-text-center gavc-padding-large">
          <div class="gavc-card__header">
            <h3 class="gavc-h3 gavc-margin-bottom"> Vous cédez votre entreprise ? </h3>
            <p class="gavc-question gavc-margin-bottom">
              Garance à vos côtés vous accompagne dans votre projet de cession,
              de transmission, de la recherche de repreneurs à la contractualisation.
            </p>
         </div>
          <div class="gavc-card__content">
            <div class="text-left">
              <div class="gavc-field">
                <div class="gavc-radio-checkbox">
                  <div class="gavc-radio">
                    <input :value="false" type="radio" id="hasIdentifyRepreneur-input-2"
                           name="hasIdentifyRepreneur-input-2" v-model="hasIdentifyRepreneur">
                    <label class="gavc-label" for="hasIdentifyRepreneur-input-2">
                      Je cherche un repreneur
                      <i class="gavc-icon gavc-icon-check" ></i>
                    </label>
                    <br>
                    <input :value="true" type="radio" id="hasIdentifyRepreneur-input-1"
                           name="hasIdentifyRepreneur-input-1" v-model="hasIdentifyRepreneur">
                    <label class="gavc-label" for="hasIdentifyRepreneur-input-1">
                      J'ai déjà identifié un repreneur
                      <i class="gavc-icon gavc-icon-check" ></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="garance-main-button"
              @click="goToRegistration"
              >
                C'est parti !
            </button>
         </div>
         <div class="gavc-card__footer gavc-margin-top">
             <p class="gavc-legende gavc-margin-top">
               Que vous cédiez votre entreprise ou souhaitiez céder les parts détenues dans une entreprise, <br>
               Garance à vos côtés vous accompagne tout au long de votre projet.
             </p>
          </div>
          <div class="gavc-background-card-text">
            <p class="gavc-background-card-text__content"> cession </p>
          </div>
        </div>

        <p class="gavc-p mt-3">
          Vous souhaitez reprendre une entreprise ? 
          <router-link to="/inscription/accueil/repreneur">
            <i class="gavc-icon gavc-icon-arrow-right" ></i> C'est par ici
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});


export default {
  name: 'CedantAccueilRegistration',

  computed: {
    ...mapFields([
      'cedantRegistrationStep',
      'cedant.hasIdentifyRepreneur',
    ]),
  },

  methods: {
    goToRegistration () {
      this.cedantRegistrationStep = 1
      this.$router.push('/inscription/cedant')
    }
  }
}
</script>
